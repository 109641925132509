<template>
  <div id="app">
    <component :is="layout">
      <transition name="fade">
        <router-view />
      </transition>
    </component>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import detectClientBrowser from "./ultilities/detectClientBrowser";

export default {
  computed: {
    layout() {
      return `${this.$route.meta.layout}`;
    },
    ...mapState(["user"]),
  },

  methods: {
    logout() {
      let payload = { SMID: this.user.profileInfo.SMID };

      this.$store.commit("toggleLayoutLoading", true);

      api
        .handleCustomerLogout(payload)
        .then((response) => {
          this.$store.commit("toggleLayoutLoading", false);
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `Signed out successfully.`,
              type: "success",
              duration: 10000,
            });

            this.$store.commit("setUser", null);
            this.$store.commit("setAccountsPayload", null);
            this.$store.commit("setSessionId", null);
            localStorage.clear();
            sessionStorage.clear();

            this.$router.push("/");
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLayoutLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    customerFootprint(payload) {
      api.customerFootprint(payload).then(() => {});
    },
  },
  mounted() {
    setTimeout(() => {
      this.$root.$el.onclick = (e) => {
        if (
          e.target.localName == "button" ||
          e.target.localName == "a" ||
          e.target.localName == "span" ||
          e.target.localName == "img"
        ) {
          let localName = e.target.localName;

          if (e.target.alt) {
            localName = e.target.localName + " " + e.target.alt;
          }

          let payload = {
            MobileNum: "",
            BVN: "",
            Msg: "Clicked" + " " + e.target.textContent + " " + localName,
            Stage: this.$router.currentRoute.path,
            Email: "",
            Screen: window.location.origin,
            Time: new Date(),
            DeviceInfo:
              "web:" +
              " " +
              detectClientBrowser +
              " " +
              "on" +
              " " +
              e.view.navigator.platform,
            APPVersion: 0,
          };
          if (this.user != undefined || null) {
            payload.MobileNum = this.user.profileInfo.phonenumber;
            payload.BVN = this.user.profileInfo.BVN;
            payload.Email = this.user.profileInfo.email;
          }
          this.customerFootprint(payload);
        }
      };
    });
  },
  onIdle() {
    this.logout();
  },
  beforeMount() {
    if (process.env.NODE_ENV == "production" && location.protocol !== "https:") {
      location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }
  },
  // created() {
  //   document.addEventListener("beforeunload", this.logout());
  // }
};
</script>

<style lang="scss">
// font import
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700;900&display=swap");

// Basic reset
html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  @media only screen and (max-width: 56.25em) {
    font-size: 56.25%;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  font-size: 1.6rem;
}
// Vue Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// Custom Aminations
@keyframes softFromBottom {
  0% {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fromTop {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes appearFromBottom {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scale(1);
  }
}

// Active sidebar
.active-bar {
  background: #fafafa;
  color: #db353a;
  width: 100%;
  display: inherit;
}

// Reuseable
.btn {
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 1.2rem 3.8rem;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;
  &__grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    padding: 0 3rem;
    margin-bottom: 2rem;
  }
  &__grid-two {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    margin-bottom: 2rem;
  }
  &__group {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
  }
  &__groups {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    &--spaced {
      margin-bottom: 2rem;
    }
  }
  &__red {
    background: #db353a;
    color: #ffffff;
  }
  &__black {
    background: #000000;
    color: #ffffff;
  }
  &__black--two {
    background: #000000;
    color: #ffffff;
    padding: 1.2rem 2rem;
    word-wrap: normal;
    display: inline-block;
  }
  &__transparent-red {
    background: #ffffff;
    color: #db353a;
    border: 1px solid #db353a;
    border-radius: 5px;
  }
  &__transparent-red-two {
    background: #ffffff;
    color: #db353a;
    border: 1px solid #db353a;
    border-radius: 5px;
    padding: 0.5rem 1.5rem;
    font-size: 1.6rem;
    display: inline-block;
  }
  &__full {
    width: 100%;
  }
  &:hover {
    opacity: 0.8;
  }
  &__back {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 3rem;
    cursor: pointer;
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: 0.05em;
    color: #000000;
  }
  &__padding {
    padding: 1.5rem 3rem;
    font-weight: 500;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

// Pin Input
.otp-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // margin: 4rem 0;
  padding: 4rem 0;
  & > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.otp-input-1 {
  width: 7rem;
  height: 4rem;
  font-size: 2rem;
  border: none;
  text-align: center;
  border-bottom: 1px solid #000000;
  &.error {
    border: 1px solid red !important;
  }

  // 768px
  @media only screen and (max-width: 48em) {
    width: 8rem;
  }

  // 480px
  @media only screen and (max-width: 30em) {
    width: 5rem;
  }
}
.otp-input {
  width: 12rem;
  height: 4rem;
  font-size: 2rem;
  border: none;
  text-align: center;
  border-bottom: 1px solid #000000;
  &.error {
    border: 1px solid red !important;
  }

  // 768px
  @media only screen and (max-width: 48em) {
    width: 8rem;
  }

  // 480px
  @media only screen and (max-width: 30em) {
    width: 5rem;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Pending features
.pending {
  cursor: not-allowed !important;
}

// Responsive breakpoints

// // 1200px
// @media only screen and (max-width: 75em) {
// }
// // 1024px
// @media only screen and (max-width: 64em) {
// }

// // 900px
// @media only screen and (max-width: 56.25em) {
// }

// // 768px
// @media only screen and (max-width: 48em) {
// }

// // 480px
// @media only screen and (max-width: 30em) {
// }
</style>
