<template>
  <div class="lds-hourglass"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.lds-hourglass {
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1.2rem solid #ffffff;
  border-color: #ffffff transparent #ffffff transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
</style>
