<template>
  <footer id="footer" class="footer">
    <div class="footer__items row">
      <div class="footer__left">
        <div class="footer__logo">OneBank</div>
        <div class="footer__logo--des">
          <a
            href="https://facebook.com/onebankng"
            title="Facebook"
            target="_blank"
            rel="noopener"
          >
            <img src="../assets/images/facebook-icon.svg" alt="facebook-icon" />
          </a>
          <a
            href="https://twitter.com/onebankng"
            title="Twitter"
            target="_blank"
            rel="noopener"
          >
            <img src="../assets/images/twitter-icon.svg" alt="twitter-icon" />
          </a>
          <a
            href="https://www.instagram.com/onebankng/"
            target="_blank"
            rel="noopener"
            title="Instagram"
          >
            <img src="../assets/images/instagram-icon.svg" alt="instagram-icon" />
          </a>
        </div>
      </div>
      <div class="footer__right">
        <div class="footer__links">
          <h5>Company</h5>
          <ul>
            <li><a href="#features">Features</a></li>
            <!-- <li><a href="#news">Latest News</a></li> -->
            <li @click="$emit('openPrivacyModal')">
              <a>Terms & Conditions</a>
            </li>
            <li @click="$emit('openPrivacyModal')">
              <a>Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div class="footer__links">
          <h5>Contact us</h5>
          <ul>
            <li>
              <a>Sterling Towers, 20 Marina Rd, Lagos Island, Lagos</a>
            </li>
            <li>
              <a href="mailto:customercare@sterling.ng">customercare@sterling.ng</a>
            </li>
            <li><a href="tel:017003270">01 700 3270</a></li>
            <li><a href="tel:2347008220000">(+234) 7008220000</a></li>
            <li><a href="tel:014484481">01-4484481</a></li>
            <li><a href="tel:018888822">01-8888822</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row flex copyright">
      <div class="copyright">Copyright © {{ date }}, Sterling Bank Ltd.</div>
      <div class="copyright copyright__des">
        <p>Licensed & regulated by the Central Bank of Nigeria</p>
        <img
          src="../assets/images/Central_Bank_of_Nigeria_logo.png"
          alt="CBN-logo"
          height="50px"
        />
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_home";
</style>
