<template>
  <el-dialog
    :visible.sync="showPrivacyModal"
    :show-close="false"
    :close-on-click-modal="true"
    style="padding: 0; text-align:left;"
    class="dialogue"
  >
    <!-- <div class="dialogue__heading">
      <h2>To - Destination</h2>
      <div class="dialogue__close" @click="closeDialogue">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3 10.0006L19.4873 3.81337C20.1709 3.12974 20.1709 2.02141 19.4873 1.33885L18.6624 0.514004C17.9786 -0.169839 16.8703 -0.169839 16.1877 0.514004L10.0006 6.70106L3.81337 0.512722C3.12974 -0.170907 2.02141 -0.170907 1.33885 0.512722L0.512722 1.33756C-0.170907 2.02141 -0.170907 3.12974 0.512722 3.8123L6.70106 10.0006L0.514004 16.1877C-0.169839 16.8715 -0.169839 17.9799 0.514004 18.6624L1.33885 19.4873C2.02247 20.1709 3.13081 20.1709 3.81337 19.4873L10.0006 13.3L16.1877 19.4873C16.8715 20.1709 17.9799 20.1709 18.6624 19.4873L19.4873 18.6624C20.1709 17.9786 20.1709 16.8703 19.4873 16.1877L13.3 10.0006Z"
            fill="#DB353A"
          />
        </svg>
      </div>
    </div> -->
    <h1>Terms & Privacy Policy</h1>
    <div class="terms">
      <div class="terms__group">
        <h2>Introduction</h2>
        <p>
          Sterling Bank Plc. (hereinafter referred to as “Sterling”, “the Bank”,
          We”, “Us” or “Our”), is committed to treating your personal
          information responsibly. Please read this Privacy Policy (“Policy“)
          carefully as it provides important information on how we handle your
          personal information.
        </p>
        <p>
          By continuing to visit our website
          <a href="https://www.sterling.ng" target="_blank" rel="noopener"
            >(www.sterling.ng)</a
          >, other customer touchpoints and utilising our Sterling Services
          (“the Services”), you accept and consent to the provisions described
          in this Policy
        </p>
      </div>

      <div class="terms__group">
        <h2>Information Collection and Use</h2>
        <p>
          We collect different types of information for various purposes to
          provide and improve our Service to you including but not limited to
          personal information/data which means any information provided by you
          with which you can be identified.
        </p>
      </div>

      <div class="terms__group">
        <h2>What do we collect?</h2>
        <p>
          When visiting or registering on our website, online banking
          applications, physical branches or accessing any of our services, you
          may be required to provide Personal Information/Data such as your
          name, contact details, identification, work and residential addresses,
          gender, positions held, forms submitted, biometric data/BVN, payment
          details, voice recordings and other enquiry details which would
          enhance your service experience
        </p>

        <h3>
          1. Details about your banking activities and transactions with us.
        </h3>
        <p>
          This includes information on any bank accounts you use, debit card
          numbers, financial history, information you provide to deliver payment
          initiation services and account information services regarding
          accounts you hold with other providers.
        </p>

        <h3>2. Information on how you use your device/ Usage Data.</h3>
        <p>
          We may also collect information that your browser sends whenever you
          visit our website or banking applications or when you access our
          Services by or through a mobile device (“Usage Data”).
        </p>
        <p>
          This Usage Data may include information such as your computer’s
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Service that you visit, the time and date of
          your visit and the time spent on those pages amongst other diagnostic
          data.. When you access the Service by or through a mobile device or
          use the mobile app, this Usage Data may include information such as
          the type of mobile device you use, your mobile device unique ID, the
          IP address of your mobile device, your mobile operating system, the
          type of mobile Internet browser you use, unique device identifiers and
          other diagnostic data.
        </p>

        <h3>3. Tracking & Cookies Data</h3>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and hold certain information.
        </p>
        <p>
          Cookies are files with small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Tracking technologies also used are
          beacons, tags, and scripts to collect and track information and to
          improve and analyze our Service.
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. You can also refuse permissions to read
          your phone data by the mobile application. However, if you do not
          accept cookies on your browser or allow permissions on your mobile
          device, our online service experience to you may be degraded and you
          may not be able to use some portions of our Service.
        </p>

        <h4>Examples of Cookies we use:</h4>
        <ul>
          <li>
            <strong>Session Cookies.</strong> We use Session Cookies to operate
            our Service. Session cookies will expire at the end of your browser
            session and allow us to link your actions during that browser
            session
            <ul>
              <li>
                Preference Cookies. We use Preference Cookies to remember your
                preferences and actions, across multiple sites.
              </li>
            </ul>
          </li>
          <li>
            <strong>Security Cookies.</strong>We use Security Cookies for
            security purposes.
            <ul>
              <li>Information from social networks or online accounts.</li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="terms__group">
        <h2>Other information</h2>
        <p>
          We may also process specific categories of information for specific
          and limited purposes, such as detecting and preventing financial crime
          or making our services available to customers.
        </p>

        <h3>1. How do we use your Information?</h3>
        <p>
          To the extent permissible under applicable law, we may use your
          information for the following legitimate actions:
        </p>

        <ul>
          <li>Verify your identity when you access your account information</li>
          <li>Respond to your requests and communicate with you</li>
          <li>For understanding your financial needs</li>
          <li>
            Prevention of crime, fraud, money laundering or terrorism financing
            activities
          </li>
          <li>Managing our risks</li>
          <li>Reviewing credit or loan eligibility</li>
          <li>
            For marketing the products and services of Sterling, related
            entities and affiliates
          </li>
          <li>Process transactions, design products and profile customers</li>
          <li>Notify you about changes to our Services</li>
          <li>
            Allow you to participate in interactive features of our Services
            when you choose to do so
          </li>
          <li>
            Provide customer care and support and for internal operations,
            including troubleshooting, data analysis, testing, research,
            security, fraud-detection, and account management
          </li>
          <li>Carry out analysis to evaluate and improve our business</li>
          <li>Monitor the usage of our Services</li>
          <li>Detect, prevent and address technical issues</li>
          <li>
            Prevent fraud and enhance security of your account or our service
            platform
          </li>
          <li>
            Comply with and enforcing applicable legal and regulatory
            requirements, relevant industry standards, contractual obligations
            and our policies
          </li>
          <li>
            Provide you with tailored content and marketing messages such as
            recommending other products or services we believe you may be
            interested in
          </li>
          <li>For other purposes required by law or regulation</li>
        </ul>

        <h3>2. How do we share your information?</h3>
        <p>
          We may share the information about you and your dealings with us, to
          the extent permitted by law, with the following:
        </p>

        <ul>
          <li>Sterling Branches and subsidiaries</li>
          <li>
            Legal/Regulatory Authorities – It may be necessary by law, legal
            process, litigation, and/or requests from public and governmental
            authorities for FITC to disclose your personal information. We may
            also disclose information about you if we determine that for
            purposes of national security, law enforcement, or other issues of
            public importance, disclosure is necessary or appropriate;
          </li>
          <li>Professional Advisers: Auditors/Legal Advisers</li>
          <li>Credit Agencies;</li>
          <li>Correspondent Banks;</li>
          <li>External Auditors;</li>
          <li>
            Strategic partners/service providers – for the purpose of providing
            our services to you. Your Personal information will not be shared
            with third parties for their marketing purposes.
          </li>
        </ul>

        <p>
          We may also disclose your Personal Information in the good faith and
          belief that such action is necessary in any of the following
          circumstances:
        </p>

        <ul>
          <li>We have your consent to share the information;</li>
          <li>
            To comply with a legal obligation;
            <ul>
              <li>
                To bring you improved service across our array of products and
                services, when permissible under relevant laws and regulations,
                by disclosing your personal information with Sterling Bank’s
                affiliated websites and businesses;
              </li>
            </ul>
          </li>
          <li>To protect and defend the rights or property of the Bank;</li>
          <li>
            To prevent or investigate possible wrongdoing in connection with our
            Service;
          </li>
          <li>
            To protect the personal safety of users of our Service or the
            public;
          </li>
          <li>To protect against legal liability;</li>
          <li>
            in the event of a reorganization, merger, or sale we may transfer
            any and all personal information we collect to the relevant third
            party or
          </li>
          <li>
            We find that your actions on our web sites or banking applications
            violate any part of our Privacy Policy;
          </li>
        </ul>

        <h4>How do we secure your Information?</h4>
        <p>
          We have implemented appropriate organizational and technical measures
          to keep your Personal Information/Data confidential and secure. This
          includes the use of encryption, access controls and other forms of
          security to ensure that your data is protected. We require all parties
          including our staff and third-parties processing data on our behalf to
          comply with relevant policies and guidelines. Where you have a
          password which grants you access to specific areas on our site or to
          any of our services, you are responsible for keeping this password
          confidential. We request that you do not to share your password or
          other authentication details (e.g. token generated codes) with anyone.
        </p>
        <p>
          Although we have taken measures to secure and keep your information
          confidential, because the security of your data is important to us,
          please be aware that no method of transmission over the Internet, or
          method of electronic storage can guarantee 100% security at all times.
          While we strive to use commercially acceptable means to protect your
          Personal Data, we cannot guarantee its absolute security, you are
          responsible for securing and maintaining the privacy of your password
          and Account/profile registration information and verifying that the
          Personal Data we maintain about you is valid, accurate and up to date.
          If we receive instructions using your account login information, we
          will consider that you have authorized the instructions and process
          your instruction accordingly and without incurring any liability for
          doing so.
        </p>
      </div>

      <div class="terms__group">
        <h2>How long do we store your Information?</h2>
        <p>
          We retain your Information for as long as the purpose for which the
          information was collected continues. The information is then securely
          destroyed unless its retention is required to satisfy legal,
          regulatory, internal compliance or accounting requirements or to
          protect Sterling’s interest.
        </p>
        <p>
          Please note that regulations may require Sterling to retain your
          personal data for a specified period even after the end of your
          banking relationship with us.
        </p>
      </div>

      <div class="terms__group">
        <h2>Information from locations outside Nigeria</h2>
        <p>
          If you are located outside Nigeria and choose to provide information
          to us, please note that the data, including Personal Data, will be
          processed in Nigeria. Your consent to this Privacy Policy followed by
          your submission of such information represents your agreement to that
          transfer.
        </p>
      </div>

      <div class="terms__group">
        <h2>Accuracy and updates of your Information</h2>
        <p>
          You are responsible for making sure the information provided to the
          Bank is accurate and should inform the Bank on any changes as it
          occurs, this will enable us to update your information with us.
        </p>
        <p>
          Any changes will affect only future uses of your Personal Information.
          Subject to applicable law, which might, from time to time, oblige us
          to store your Personal Information for a certain period of time, we
          will respect your wishes to correct inaccurate information. Otherwise,
          we will hold your Personal Information for as long as we believe it
          will help us achieve our objectives as detailed in this Privacy
          Policy.
        </p>
      </div>

      <div class="terms__group">
        <h2>Know your rights</h2>
        <p>
          You have certain rights in relation to the personal data we collect as
          provided by the Nigeria Data Protection Regulation 2019 (NDPR), these
          rights include:
        </p>

        <ul>
          <li>A right to access your personal data</li>
          <li>A right to rectify/update your information in our possession</li>
          <li>A right to request the erasure of personal data</li>
          <li>
            A right to withdraw your consent to processing of personal data.
            This will however not affect the legality of processing carried out
            prior to any such withdrawal
          </li>
          <li>
            Right to object to processing of personal data. This will only be
            applicable where there are no legal or operational reasons
          </li>
          <li>
            Request that your personal data be made available to you in a common
            electronic format and/or request that such data be sent to a third
            party
          </li>
          <li>
            Request that your information be erased. We might continue to retain
            such data if there are valid legal, regulatory or operational
            reasons
          </li>
        </ul>

        <p>
          These rights are however subject certain limitations as provided in
          the NDPR.
        </p>
      </div>

      <div class="terms__group">
        <h2>Privacy of Children</h2>
        <p>
          Sterling does not knowingly collect names, email addresses, or any
          other personally identifiable information from children through the
          internet or other touchpoints. We do not allow children under the age
          of 18 to open accounts nor provide online banking for children less
          than 18 years of age without the consent of a guardian. If you are a
          parent or guardian and you are aware that your child has provided us
          with Personal Data, please contact us.
        </p>
      </div>

      <div class="terms__group">
        <h2>Social Media Platforms</h2>
        <p>
          We operate and communicate through our designated pages and accounts
          on some social media platforms to communicate and engage with our
          customers. We monitor and record comments and posts made about us on
          these channels so that we can improve our Services. The general public
          can access and read any information posted on these sites. Please note
          that any content you post to such social media platforms is subject to
          the applicable social media platform’s terms of use and privacy
          policies. We recommend that you review the information carefully in
          order to better understand your rights and obligations regarding such
          content.
        </p>
        <p>
          Our Services may allow you to connect and share your actions,
          comments, content, and information publicly or with friends. We are
          not responsible for maintaining the confidentiality of any information
          you share publicly or with friends.
        </p>
        <p>
          Our Service may also allow you to connect with us on, share on, and
          use third-party websites, applications, and services. Please be
          mindful of your personal privacy needs and the privacy needs of
          others, as you choose whom to connect with and what to share and make
          public. We cannot control the privacy or security of information you
          choose to make public or share with others. We also do not control the
          privacy practices of third parties. Please contact those sites and
          services directly if you want to learn about their privacy practices.
        </p>

        <h3>Third Party Websites</h3>
        <p>
          Our website, related websites and mobile applications may have links
          to or from other websites that are not operated by us. We have no
          control over and assume no responsibility for the security, privacy
          practices or content of third party websites or services. We recommend
          that you always read the privacy and security statements on these
          websites.
        </p>

        <h3>Service Providers</h3>
        <p>
          We may employ third party companies and individuals to facilitate our
          Service (“Service Providers”), to provide the Service on our behalf,
          to perform specific Service-related roles or to assist us in analyzing
          how our Service is used.
        </p>
        <p>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose outside of the service-specific need for which
          the data is required.
        </p>

        <h3>Changes to the Policy</h3>
        <p>
          This Privacy Policy is effective as of the date stated above and will
          remain in effect except with respect to any changes in its provisions
          in the future, which will be in effect immediately after being posted
          on our website.
        </p>
        <p>
          Based on the changing nature of privacy laws, user needs and our
          business, we may modify this Privacy Policy from time to time. Any
          change to our privacy policy will be communicated on our website, via
          email or by placing a notice on our Platform and this will be
          effective as soon as published. Accordingly, we encourage periodic
          reviews of this Privacy Policy for awareness of any changes that may
          have occurred.
        </p>
        <p>
          Your continued use of the Platform after we post any modifications to
          the Privacy Policy on this page will constitute your acknowledgment of
          the modifications and your consent to abide and be bound by the
          modified Privacy Policy.
        </p>
      </div>

      <div class="terms__group">
        <h2>Career Platforms</h2>
        <p>
          As part of our recruitment process and as an applicant, you explicitly
          consent to the collection, use, transfer, and storage or in any other
          form of your personal data contained in application forms/letters,
          curriculum vitae (CV)/resumes obtained from your identity document(s)
          or collected through interviews/other forms assessment by Sterling
          Bank Plc or its affiliates. This information is for the exclusive
          purpose of assessing and evaluating applicants suitability for
          employment in any current or prospective position within our
          organization, verifying applicants identity and the accuracy of your
          details provided to us or for other related purposes. We shall, in
          line with our internal policies, controls and relevant Data Protection
          Regulations ensure that this data is not disclosed or assessed by
          unauthorized persons.
        </p>
        <p>
          By providing any information on Sterling’s career page, you confirm
          that you have read the terms and privacy statement and accept it. As
          an applicant, you consent to Sterling Bank using the data provided in
          accordance with terms described above.
        </p>
      </div>

      <div class="terms__group">
        <h2>Contact Us</h2>
        <p>
          If you have any questions, comments or requests in relation to this
          Privacy Policy or objections, complaints or requirements in relation
          to the use of your personal data, please contact us by sending an
          email to customercare@sterling.ng. We will endeavor to treat your
          requests as soon as possible.
        </p>
        <p>You can also write a letter addressed as follows:</p>
        <p><strong>The Data Protection Officer</strong></p>
        <p>Sterling Bank Plc</p>
        <p>20 Marina Lagos</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ["showPrivacyModal"],
  methods: {
    closeDialogue() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  line-height: 6rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-align: center;
  margin-bottom: 1.6rem;

  // 768px
  @media only screen and (max-width: 48em) {
    font-size: 3rem;
    line-height: 4rem;
  }
}
.terms {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.05em;
  color: #000000;
  overflow-wrap: break-word;
  &__group {
    margin-bottom: 3rem;
    h2 {
    }
    h3 {
    }
    h4 {
    }
    p {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 1.6rem;
    }
    ul {
      margin-left: 3rem;
    }
    li {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 1rem;
    }
    a {
      text-decoration: none;
    }
  }
}
</style>
