<template>
  <div class="user">
    <div class="user__inits">
      {{ initials }}
    </div>
    <p>{{ user.profileInfo.fullname }}</p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["user"])
  },
  data() {
    return {
      initials: ""
    };
  },
  mounted() {
    const userNames = this.user.profileInfo.fullname.split(" ");
    this.initials = userNames.map(([v]) => v).join("");
  }
};
</script>

<style lang="scss" scoped>
.user {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  &__inits {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #db353a;
    color: #db353a;
    padding: 1rem;
    border-radius: 50%;
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
  p {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: 0.05em;
    color: #000000;
    text-align: center;
  }
  // 768px
  @media only screen and (min-width: 48em) {
    flex-direction: row;
    padding: 0;
  }
}
</style>
