<template>
  <div class="home-layout">
    <div class="home-layout__left">
      <app-carousel></app-carousel>
    </div>
    <div class="home-layout__right">
      <transition name="fade">
        <slot />
      </transition>
    </div>
  </div>
</template>

<script>
import Carousel from "@/widgets/Carousel";

export default {
  components: {
    "app-carousel": Carousel
  }
};
</script>

<style lang="scss" scoped>
.home-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  &__left {
    grid-column: 1 / 2;
    position: fixed;
    height: 100%;
    width: 50%;
  }
  &__right {
    padding: 10%;
    // padding: 10rem 6rem;
    grid-column: 2 / -1;
  }

  // 768px
  @media only screen and (max-width: 48em) {
    grid-template-columns: 1fr;
    &__left {
      display: none;
    }
    &__right {
      grid-column: 1 / -1;
      padding: 5%;
    }
  }
}
</style>
