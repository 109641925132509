<template>
  <div class="container">
    <aside class="aside">
      <app-nav-user class="user"></app-nav-user>
      <nav class="nav">
        <router-link to="/dashboard-home" class="nav__item">
          <a class="nav__link">
            <div>
              <img src="../../assets/images/dashboard-icon.svg" alt="dashboard-icon" />
              <span class="nav__text">Dashboard</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link>

        <router-link to="/dashboard/transfer" class="nav__item">
          <a class="nav__link">
            <div>
              <img src="../../assets/images/sidenav-transfer.svg" alt="trasn-icon" />
              <span class="nav__text">Transfer</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link>

        <router-link to="/dashboard/airtime-and-data" class="nav__item">
          <a class="nav__link">
            <div>
              <img
                src="../../assets/images/sidenav-airtimedata.svg"
                alt="airtimedata-icon"
              />
              <span class="nav__text">Airtime and Data</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link>

        <!-- <router-link to="" class="nav__item pending">
          <a class="nav__link">
            <div>
              <img
                src="../../assets/images/sidenav-merchant.svg"
                alt="merchant-icon"
              />
              <span class="nav__text">Merchant Pay</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link> -->

        <!-- <router-link to="" class="nav__item pending">
          <a class="nav__link">
            <div>
              <img
                src="../../assets/images/sidenav-giving.svg"
                alt="giving-icon"
              />
              <span class="nav__text">Giving</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link> -->

        <!-- <router-link to="" class="nav__item pending">
          <a class="nav__link">
            <div>
              <img
                src="../../assets/images/sidenav-budgetplan.svg"
                alt="budgetplan-icon"
              />
              <span class="nav__text">Budget Plan</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link> -->

        <!-- <router-link to="" class="nav__item pending">
          <a class="nav__link">
            <div>
              <img
                src="../../assets/images/sidenav-investment.svg"
                alt="investment-icon"
              />
              <span class="nav__text">Investment and Loan</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link> -->

        <!-- <router-link to="/pickup/actions" class="nav__item">
          <a class="nav__link">
            <div>
              <img
                src="../../assets/images/sidenav-pickup.svg"
                alt="pickup-icon"
              />
              <span class="nav__text">Cash Pick-up</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link> -->

        <router-link to="/dashboard/cards/cards-management" class="nav__item">
          <a class="nav__link">
            <div>
              <img src="../../assets/images/sidenav-cards.svg" alt="cards-icon" />
              <span class="nav__text">Cards</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link>

        <!-- <router-link to="" class="nav__item pending">
          <a class="nav__link">
            <div>
              <img
                src="../../assets/images/sidenav-accounts.svg"
                alt="accounts-icon"
              />
              <span class="nav__text">Accounts</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link> -->

        <router-link to="/support/actions" class="nav__item">
          <a class="nav__link">
            <div>
              <img src="../../assets/images/support-icon.svg" alt="support-icon" />
              <span class="nav__text">Settings and Support</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link>

        <!-- <router-link to="" class="nav__item pending">
          <a class="nav__link">
            <div>
              <img
                src="../../assets/images/sidenav-cheque.svg"
                alt="cheques-icon"
              />
              <span class="nav__text">Cheques</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link> -->

        <!-- <router-link to="" class="nav__item pending">
          <a class="nav__link">
            <div>
              <img
                src="../../assets/images/sidenav-referral.svg"
                alt="referral-icon"
              />
              <span class="nav__text">Referrals</span>
            </div>
            <img src="../../assets/images/sidenav-down.svg" alt="down-icon" />
          </a>
        </router-link> -->
      </nav>
    </aside>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import NavUser from "@/components/layouts/NavUser.vue";
export default {
  components: {
    "app-nav-user": NavUser,
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    logout() {
      let payload = { SMID: this.user.profileInfo.SMID };

      this.$store.commit("toggleLayoutLoading", true);

      api
        .handleCustomerLogout(payload)
        .then((response) => {
          this.$store.commit("toggleLayoutLoading", false);
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `Signed out successfully.`,
              type: "success",
              duration: 10000,
            });

            this.$store.commit("setUser", null);
            this.$store.commit("setAccountsPayload", null);
            this.$store.commit("setSessionId", null);
            localStorage.clear();
            sessionStorage.clear();

            this.$router.replace("/login");
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLayoutLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.aside {
  height: 100%;
}
.user {
  display: flex;
  // 768px
  @media only screen and (min-width: 48em) {
    display: none;
  }
}
.nav {
  height: calc(100% - 14rem);
  display: flex;
  flex-direction: column;
  &__item {
    display: block;
    text-decoration: none;
    background-color: #ffffff;
    border-top: 0.3rem solid #fafafa;
    border-bottom: 0.3rem solid #fafafa;
    transition: background 0.2s;

    &:hover,
    &:active,
    &:focus {
      color: #db353a;
      background: #fafafa;
      background: linear-gradient(0deg, #fff5f6, #fff5f6), #ffffff;
    }
  }
  &__link {
    div {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 1.2rem 2rem;
    text-decoration: none;
  }

  &__text {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
    display: flex;
    letter-spacing: 0.05em;
    color: #000000;
  }
}
</style>
