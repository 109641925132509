var DeviceName = "Unknown Device Model";

if (
  /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(
    window.navigator.userAgent
  )
) {
  DeviceName = "Tablet";
} else if (
  /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    window.navigator.userAgent
  )
) {
  DeviceName = "Mobile";
} else DeviceName = "Desktop";

export default DeviceName;
