export default {
  // serverUriOne: "https://pass.sterling.ng/sterlingmobile2/api/v1",
  // serverUriTwo: "https://pass.sterling.ng/sterlingmobileft/api/v1",

  // serverUriOne: process.env.VUE_APP_URL_ONE,
  // serverUriTwo: process.env.VUE_APP_URL_TWO,

  // serverUriOne:
  //   "https://sterlingmobileonboardingapi.sterlingapps.p.azurewebsites.net/sterlingmobile2/api/v1",
  serverUriTwo:
    "https://onebank.sterling.ng/sterlingmobileft/api/v1",
  serverUriOne:
    "https://onebank.sterling.ng/sterlingmobile2/api/v1",

  serverUriThree: process.env.VUE_APP_URL_THREE,
  serverUriFour: process.env.VUE_APP_URL_FOUR,
  serverUriFive: process.env.VUE_APP_URL_FIVE,
  serverUriFiveAuthUsername: process.env.VUE_APP_URL_FIVE_AUTH_USERNAME,
  serverUriFiveAuthPassword: process.env.VUE_APP_URL_FIVE_AUTH_PASSWORD,

  clientId: process.env.VUE_APP_CLIENT_ID,
  clientSecret: process.env.VUE_APP_CLIENT_SECRET,
  clientKey: process.env.VUE_APP_CLIENT_KEY,
  otpClientId: "2",
};
