import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuelidate from "vuelidate";
import OtpInput from "@bachdgvn/vue-otp-input";
import VueAppInsights from "vue-application-insights";
import VueSocialSharing from 'vue-social-sharing'


//Elements UI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import "./assets/styles/element-variables.scss";

Vue.use(ElementUI, { locale });
Vue.use(Vuelidate);
Vue.component("v-otp-input", OtpInput);

// Importing Dayjs
import dayjs from "dayjs";

// application Insights
Vue.use(VueAppInsights, {
  id: "d4640f84-df56-4046-9d32-48670b2d7eb6"
});

//SocialSharing
Vue.use(VueSocialSharing);

// Detecting Inactivity
import IdleVue from "idle-vue";

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 300000,
  startAtIdle: false
});

// Layouts
import HomeLayout from "@/layouts/HomeLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";

// Widgets
import Loader from "@/widgets/Loader.vue";

import "./registerServiceWorker";

Vue.component("home-layout", HomeLayout);
Vue.component("default-layout", DefaultLayout);
Vue.component("dashboard-layout", DashboardLayout);
Vue.component("loading", Loader);

Vue.config.productionTip = false;

// Global Filters
Vue.filter("formatCurrency", digit => {
  let num;
  if (typeof digit !== "string") {
    if (typeof digit === "number") {
      num = digit;
    }
  } else num = Number.parseFloat(digit, 10);
  if (typeof num !== undefined) {
    num = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    return `#${num}`;
  }
});

Vue.filter("formatCurrencyTwo", digit => {
  let num;
  if (typeof digit !== "string") {
    if (typeof digit === "number") {
      num = digit;
    }
  } else num = Number.parseFloat(digit, 10);
  if (typeof num !== undefined) {
    num = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    return `${num}`;
  }
});

Vue.filter("formatCurrencyThree", digit => {
  if (isNaN(digit)) return digit;

  let num;
  num = Number.parseFloat(digit, 10);
  num = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return `${num}`;
});

Vue.filter("formatCurrencySymbol", value => {
  let symbol;
  switch (value) {
    case "NGN":
      symbol = "#";
      break;
    case "USD":
      symbol = "$";
      break;
    case "GBP":
      symbol = "£";
      break;
    case "EURO":
    case "Euro":
      symbol = "€";
      break;

    default:
      symbol = value;
      break;
  }
  return symbol;
});

Vue.filter("formatDate", value => {
  if (value) {
    return dayjs(String(value)).format("DD/MM/YYYY hh:mm:ss a");
  }
});

Vue.filter("capitalize", value => {
  return value
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
});

Vue.filter("formatCard", value => {
  // let cardnumber = value;
  // let first8 = cardnumber.substring(0, 8);
  // let last4 = cardnumber.substring(cardnumber.length - 4);

  // let mask = cardnumber.substring(8, cardnumber.length - 4).replace(/\d/g, "*");
  // return first8 + mask + last4;

  let cardnumber = value;

  let firstFour = cardnumber.slice(0, 4);
  let lastFour = cardnumber.slice(-4);
  let maskedNumber = cardnumber.slice(-8, -4);
  let remainingNumber = cardnumber.slice(4, -8);

  let mask = maskedNumber.replace(/\d/g, "*");
  return `${firstFour} ${remainingNumber} ${mask} ${lastFour}`;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
