import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";

// import createPersistedState from "vuex-persistedstate";
import VuexPersist from "vuex-persist";
import CryptoJS from "crypto-js";

import config from "../config";
const encryptionToken = config.clientKey;

import SecureLS from "secure-ls";
let ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: encryptionToken,
});

if (localStorage["vuexState"]) {
  let keyValye = "user" + '"' + ":null";
  let decryptedValue = decryptToken();
  if (decryptedValue.includes(keyValye)) {
    localStorage.clear();
    sessionStorage.clear();
  }
}

function decryptToken() {
  let encryptedText = localStorage.getItem("vuexState");
  let bytes = CryptoJS.AES.decrypt(encryptedText, encryptionToken);
  let decryptedText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedText;
}

const vuexLocalStorage = new VuexPersist({
  key: "vuexState",
  // storage: .localStorage,
  // restoreState: clearrestoreState,
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
});

import api from "@/api/api.js";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import "@/assets/styles/element-variables.scss";

Vue.use(ElementUI, { locale });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    layoutLoading: false,
    user: null,
    sessionId: null,
    accountsPayload: null,
    userCardAccounts: null,
    userAccounts: null,
    userAccountsCopy: null,
    hideBalState: false,
    userFcyAccounts: null,
    userDetails: null,
    userDetailsTwo: null,
    cardDetail: null,
    allCategories: null,
    billCategory: null,
    billCategoryTwo: null,
    billOtherCategories: null,
    billSubCategory: null,
    eNairaUserData: null,
    eNairaUserBalance: null,
    userBVNData: null,
    inputBVN: null,
    productData: null,
    passportName: null,
    passportUrl: null,
    signatureName: null,
    signatureUrl: null,
    forgetPasswordData: null,
  },
  mutations: {
    toggleLoading(state, payload) {
      state.loading = payload;
    },
    toggleLayoutLoading(state, payload) {
      state.layoutLoading = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setAccountsPayload(state, payload) {
      state.accountsPayload = payload;
    },
    setAccounts(state, payload) {
      state.userAccounts = payload;
    },
    setAccountsCopy(state, payload) {
      state.userAccountsCopy = payload;
    },
    setSessionId(state, payload) {
      state.sessionId = payload;
    },
    setCardAccounts(state, payload) {
      state.userCardAccounts = payload;
    },
    setFcyAccounts(state, payload) {
      state.userFcyAccounts = payload;
    },
    setDetails(state, payload) {
      state.userDetails = payload;
    },
    setDetailsTwo(state, payload) {
      state.userDetailsTwo = payload;
    },
    setCard(state, payload) {
      state.cardDetail = payload;
    },
    setAllCategories(state, payload) {
      state.allCategories = payload;
    },
    setCategory(state, payload) {
      state.billCategory = payload;
    },
    setCategoryTwo(state, payload) {
      state.billCategoryTwo = payload;
    },
    setOtherCategories(state, payload) {
      state.billOtherCategories = payload;
    },
    setSubCategory(state, payload) {
      state.billSubCategory = payload;
    },
    setHideState(state, payload) {
      state.hideBalState = payload;
    },
    setModifiedUserAccounts(state, payload) {
      if (payload) {
        state.userAccountsCopy.forEach((account) => {
          account.WorkingBalance = account.WorkingBalance.toString().replace(
            /\d/g,
            "*"
          );
          account.Currency_Code = account.Currency_Code.toString().replace(
            /\w/g,
            "*"
          );
        });
      } else if (!payload) {
        state.userAccountsCopy = null;
        state.userAccountsCopy = state.userAccounts.map((account) => {
          return {
            ACCT_TYPE: account.ACCT_TYPE,
            NUBAN: account.NUBAN,
            WorkingBalance: account.WorkingBalance,
            Currency_Code: account.Currency_Code,
            AccountDescp: account.AccountDescp,
          };
        });
      }
    },
    setEnairaUserData(state, payload) {
      state.eNairaUserData = payload;
    },
    urlForPassportPhoto(state, payload) {
      state.passportUrl = payload;
    },
    fileNameForPassport(state, payload) {
      state.passportName = payload;
    },
    urlForSignature(state, payload) {
      state.signatureUrl = payload;
    },
    fileNameForSignature(state, payload) {
      state.signatureName = payload;
    },
    setUserBVNData(state, payload) {
      state.userBVNData = payload;
    },
    setInputBVN(state, payload) {
      state.inputBVN = payload;
    },
    setProductData(state, payload) {
      state.productData = payload;
    },
    seteNairaUserBalance(state, payload) {
      state.eNairaUserBalance = payload;
    },
    setForgetPasswordData(state, payload) {
      state.forgetPasswordData = payload;
    },
  },
  actions: {
    setCustomerDetails(context, payload) {
      context.commit("setDetails", payload);
    },
    setCustomerDetailsTwo(context, payload) {
      context.commit("setDetailsTwo", payload);
    },
    setCardDetails(context, payload) {
      context.commit("setCard", payload);
    },
    setCategoryDetails(context, payload) {
      context.commit("setCategory", payload);
    },
    setAllCategoriesDetails(context, payload) {
      context.commit("setAllCategories", payload);
    },
    setCategoryTwoDetails(context, payload) {
      context.commit("setCategoryTwo", payload);
    },
    setOtherCategoryDetails(context, payload) {
      context.commit("setOtherCategories", payload);
    },
    setSubCategoryDetails(context, payload) {
      context.commit("setSubCategory", payload);
    },
    async handleGetUserAccounts({ commit, state }) {
      let allAccounts = [];

      commit("toggleLoading", true);

      // Get all accounts by BVN
      await api
        .getAccountsByBvn(state.accountsPayload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let userAccounts = response.Data;
            allAccounts = [...userAccounts];
            commit("setCardAccounts", userAccounts);
          } else if (responseMessage == "Accounts could not be retrieved") {
            ElementUI.Message({
              showClose: true,
              message: "You only have a wallet account, kindly upgrade",
              type: "warning",
            });
          } else {
            ElementUI.Message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          commit("toggleLoading", false);
          ElementUI.Message({
            showClose: true,
            message: `${error}`,
            type: "error",
          });
        });

      // Adding wallet account
      // let walletDetails = state.user.walletInfo;
      // let walletAccountMod = {
      //   ACCT_TYPE: "Wallet",
      //   NUBAN: walletDetails.nuban,
      //   WorkingBalance: walletDetails.availablebalance,
      //   Currency_Code: walletDetails.CURRENCYCODE,
      //   AccountDescp: walletDetails.fullname,
      // };
      // allAccounts.push(walletAccountMod);

      let payload = {
        // mobile: state.user.walletInfo.phone,
        mobile: state.user.profileInfo.phonenumber,
      };

      await api
        .getWalletDetails(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let userWallet = response.Data.data;
            let walletAccount = [];
            walletAccount.push(userWallet);
            let walletAccountMod = walletAccount.map((item) => ({
              ACCT_TYPE: "Wallet",
              NUBAN: item.nuban,
              WorkingBalance: item.availablebalance,
              Currency_Code: item.CURRENCYCODE,
              AccountDescp: item.fullname,
            }));

            allAccounts.push(walletAccountMod[0]);
          } else {
            ElementUI.Message({
              showClose: true,
              message: `${responseMessage}`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          commit("toggleLoading", false);
          ElementUI.Message({
            showClose: true,
            message: `${error}`,
            type: "error",
          });
        });

      commit("setAccounts", allAccounts);

      // Creating account copy
      let accountsDuplicates = allAccounts.map((account) => {
        return {
          ACCT_TYPE: account.ACCT_TYPE,
          NUBAN: account.NUBAN,
          WorkingBalance: account.WorkingBalance,
          Currency_Code: account.Currency_Code,
          AccountDescp: account.AccountDescp,
        };
      });
      commit("setAccountsCopy", accountsDuplicates);

      // Filtering out FCY accounts
      let fcyAccounts = allAccounts.filter(
        (account) => account.Currency_Code !== "NGN"
      );
      commit("setFcyAccounts", fcyAccounts);

      commit("toggleLoading", false);
      router.push("/dashboard-home");
    },
  },
  modules: {},
  // plugins: [createPersistedState({ storage: window.localStorage })],
  plugins: [vuexLocalStorage.plugin],
  // plugins: [
  //   createPersistedState({
  //     storage: {
  //       getItem: key => ls.get(key),
  //       setItem: (key, value) => ls.set(key, value),
  //       removeItem: key => ls.remove(key)
  //     }
  //   })
  // ]
});
