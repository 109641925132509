<template>
  <div class="home">
    <div class="nav__wrapper">
      <nav class="nav row">
        <div class="nav__logo">
          <img
            @click="$router.push('/')"
            class="main__logo"
            src="../assets/images/onebank-logo.svg"
            alt="OneBank Logo"
          />
        </div>
        <ul class="nav__left">
          <li class="nav__link">
            <a href="#features" class="nav__item">Features</a>
          </li>
          <!-- <li class="nav__link">
            <a href="#news" class="nav__item">Latest News</a>
          </li> -->
          <li class="nav__link">
            <a href="#footer" class="nav__item">Contact us</a>
          </li>
          <!-- <li class="nav__link" @click="$router.push('/developers')">
            <a class="nav__item">Developers</a>
          </li> -->
        </ul>
        <ul class="nav__right">
          <li class="nav__link" @click="$router.push('/login')">
            <a class="nav__item nav__btn nav__btn--white">Sign In</a>
          </li>
          <li class="nav__link" @click="$router.push('/signup/existing-user')">
            <a class="nav__item nav__btn nav__btn--red">Register</a>
          </li>
        </ul>

        <img
          class="nav__toggle--open"
          ref="openBtn"
          src="../assets/images/toggle.svg"
          alt="toggle-icon"
        />

        <div class="nav-mobile" ref="mobileMenu">
          <div class="nav-mobile__heading">
            <img
              class="nav-mobile__logo"
              @click="$router.push('/')"
              src="../assets/images/onebank-logo.svg"
              alt="OneBank Logo"
            />
            <img
              class="close-icon"
              ref="closeBtn"
              src="../assets/images/toggle-close.svg"
              alt="toggle-clsoe-icon"
            />
          </div>
          <ul class="nav-mobile__menu" ref="mobileLinks">
            <li class="nav__link">
              <a href="#features" class="nav__item">Features</a>
            </li>
            <!-- <li class="nav__link">
              <a href="#news" class="nav__item">Latest News</a>
            </li> -->
            <li class="nav__link">
              <a href="#footer" class="nav__item">Contact us</a>
            </li>
            <!-- <li class="nav__link" @click="$router.push('/developers')">
              <a class="nav__item">Developers</a>
            </li> -->
            <li class="nav__link" @click="$router.push('/login')">
              <a class="nav__item nav__btn nav__btn--white">Sign In</a>
            </li>
            <li class="nav__link" @click="$router.push('/signup/existing-user')">
              <a class="nav__item nav__btn nav__btn--red">Register</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <section class="hero">
      <div class="hero__wrapper row">
        <div class="hero__left">
          <h1 class="hero__heading">A New Way to Live</h1>
          <h2 class="hero__sub-heading">Save. Invest. Borrow. Transact.</h2>
          <p class="hero__text">
            OneBank is a 100% digital banking app brought to you by Sterling bank. You can
            open an account, send and receive money, invest and borrow across multiple
            devices.
          </p>

          <ul class="hero__btns">
            <li class="nav__link" @click="$router.push('/signup/existing-user')">
              <a class="nav__item nav__btn nav__btn--red">Get Started</a>
            </li>
            <li class="nav__link" @click="$router.push('/login')">
              <a class="nav__item nav__btn nav__btn--white">Sign In</a>
            </li>
          </ul>
          <div class="store">
            <a
              href="https://play.google.com/store/apps/details?id=com.sterlingng.sterlingmobile"
              target="_blank"
              rel="noopener"
              class=""
            >
              <img src="../assets/images/play-store.svg" alt="play-store icon" />
            </a>
            <a
              href="https://apps.apple.com/ng/app/sterling-onebank/id1489139430"
              target="_blank"
              rel="noopener"
              class=""
            >
              <img src="../assets/images/app-store.svg" alt="app-store-icon" />
            </a>
            <a class="">
              <img src="../assets/images/web-app.svg" alt="web-app-icon" />
            </a>
          </div>
        </div>
        <div class="hero__right">
          <img
            src="../assets/images/landing-image-two.png"
            srcset="
              ../assets/images/landing-image-one.png    400w,
              ../assets/images/landing-image-two.png    600w,
              ../assets/images/landing-image-three.png 1000w
            "
            alt="landing-image"
          />
        </div>
      </div>
    </section>

    <section id="features" class="features">
      <h2 class="features__sub-heading">Features & Benefits</h2>
      <div class="features__cards row">
        <div class="card">
          <img
            class="card__img"
            src="../assets/images/self-onboarding.png"
            alt="self-onboarding-icon"
          />
          <h4>Self Onboarding</h4>
          <p>Create an account from the convenience of your mobile phone or laptop</p>
        </div>
        <div class="card">
          <img
            class="card__img"
            src="../assets/images/airtime-and-data.png"
            alt="/airtime-and-dataicon"
          />
          <h4>Airtime and Data</h4>
          <p>Buy airtime and data for yourself and others without stress</p>
        </div>
        <div class="card">
          <img class="card__img" src="../assets/images/bills.png" alt="/bills-icon" />
          <h4>Bills</h4>
          <p>Pay for airline tickets, cable and internet subscription seamlessly</p>
        </div>
        <div class="card">
          <img
            class="card__img"
            src="../assets/images/investment.png"
            alt="/investment-icon"
          />
          <h4>Investments</h4>
          <p>Enjoy up to 60% returns on your Naira and Dollar investments on Doubble</p>
        </div>
        <div class="card">
          <img class="card__img" src="../assets/images/loans.png" alt="/loans-icon" />
          <h4>Loans</h4>
          <p>Enjoy quick loans of up to ₦ 5 million in 5 minutes to meet urgent needs</p>
        </div>
        <div class="card">
          <img class="card__img" src="../assets/images/cards.png" alt="/cards-icon" />
          <h4>Cards</h4>
          <p>
            Request a debit card and have it delivered to you for free within 48 hours
          </p>
        </div>
        <div class="card">
          <img
            class="card__img"
            src="../assets/images/foreign-transfer.png"
            alt="/foreign-transfer-icon"
          />
          <h4>Foreign Transfers</h4>
          <p>
            You can transfer money from your account to anywhere around the world within
            seconds
          </p>
        </div>
      </div>
    </section>

    <section class="extra">
      <h2 class="sub-heading product__padding">More For You</h2>
      <div class="product row">
        <div class="product__left">
          <img src="../assets/images/specta-new.png" alt="specta" />
        </div>
        <div class="product__right order--two">
          <h3 class="specta">Specta</h3>
          <p>
            Specta is an online lending platform that gives you loans of up to ₦ 5 Million
            in one transaction all within 5 minutes! No collateral, no paperwork and no
            visit to any office.
            <br />
            <br />With Specta, you can take care of urgent personal and business needs
            anywhere, anytime.
          </p>
        </div>
      </div>
      <div class="product row">
        <div class="product__left">
          <img src="../assets/images/doubble-new.png" alt="doubble" />
        </div>
        <div class="product__right order">
          <h3 class="doubble">Doubble</h3>
          <p>
            Stay ahead of the market and enjoy up to 100% returns on your Naira and Dollar
            investments.
          </p>
        </div>
      </div>
      <!-- <div class="product row">
        <div class="product__left">
          <img src="../assets/images/free-debit-card.png" alt="debit-card" />
        </div>
        <div class="product__right order--two">
          <h3 class="free-debit">Free Debit <span class="card">Card </span></h3>
          <p>
            Open an account on OneBank and request a verve card at no cost after
            performing your first transaction.
            <br />
            <br />Guess what? The card also gets delivered to you for FREE!.
          </p>
        </div>
      </div> -->
    </section>

    <section class="testimonials">
      <h2 class="sub-heading">What Customers Say About Us</h2>
      <el-carousel indicator-position="outside" class="row carousel-wrapper">
        <el-carousel-item class="testimonial">
          <img src="../assets/images/testimonial-icon.svg" alt="testimonial-icon" />
          <p>
            This app is one of a kind, user friendly and fantastically decorated with
            great features. I think, other banks should learn from sterlingbank when it
            comes to stuffs like this.
          </p>
          <div class="customer">
            <!-- <span>AF</span> -->
            <span>Ainerua Francis</span>
          </div>
        </el-carousel-item>
        <el-carousel-item class="testimonial">
          <img src="../assets/images/testimonial-icon.svg" alt="testimonial-icon" />
          <p>
            This app is awesome!! Very easy to use and it just makes things very much
            easier😊😊😊.
          </p>
          <div class="customer">
            <!-- <span>AO</span> -->
            <span>Adesewa Oluwagbeminija</span>
          </div>
        </el-carousel-item>
        <el-carousel-item class="testimonial">
          <img src="../assets/images/testimonial-icon.svg" alt="testimonial-icon" />
          <p>
            Sterling Onebank is a stellar application. It's great because it provides more
            than one function; loans and investments (Specta and Doubble), also mobile
            wallets. I recommend everyone to download the application.
          </p>
          <div class="customer">
            <!-- <span>AA</span> -->
            <span>Adedamola Aromolaran</span>
          </div>
        </el-carousel-item>
        <el-carousel-item class="testimonial">
          <img src="../assets/images/testimonial-icon.svg" alt="testimonial-icon" />
          <p>
            Wow. This app is really nice. From the first time when I signed up I never
            believed it would be this effective in making my life easier. I can save,
            borrow and invest on the same app it is really nice.
          </p>
          <div class="customer">
            <!-- <span>SO</span> -->
            <span>Sarah Okosun</span>
          </div>
        </el-carousel-item>
      </el-carousel>
    </section>

    <!-- <section id="news" class="news">
      <h2 class="sub-heading">Latest News</h2>
      <div
        class="news__blogs row"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div
          class="blog"
          v-for="(blog, index) in news"
          :key="index"
          @click="openBlog(blog.Url)"
        >
          <img
            class="blog__image"
            :src="blog.UrlToImage"
            :alt="blog.SourceName"
          />
          <div class="blog__details">
            <h5>{{ blog.PublishedAt }}</h5>
            <h4>{{ blog.Title }}</h4>
            <p>
              {{ blog.Description }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="news__link row"
        v-if="news.length > 0 && !maxNews"
        @click="showMoreNews"
      >
        <a class="news__more">View More</a>
      </div>
    </section> -->

    <app-footer @openPrivacyModal="showPrivacyModal = !showPrivacyModal"></app-footer>

    <app-privacy-policy
      v-if="showPrivacyModal"
      :showPrivacyModal="showPrivacyModal"
      @close="showPrivacyModal = false"
    ></app-privacy-policy>
  </div>
</template>

<script>
import api from "@/api/api.js";
import PrivacyPolicy from "@/widgets/PrivacyPolicy.vue";
import AppFooter from "@/views/Footer.vue";
export default {
  components: {
    "app-privacy-policy": PrivacyPolicy,
    "app-footer": AppFooter,
  },
  data() {
    return {
      loading: false,
      news: [],
      allNews: [],
      maxNews: false,
      showPrivacyModal: false,
    };
  },
  methods: {
    openBlog(url) {
      window.open(url, "_blank").focus();
    },
    showMoreNews() {
      let moreNews = this.allNews.slice(3, this.allNews.length);
      this.news = [...this.news, ...moreNews];
      this.maxNews = true;
    },
    fetchNews() {
      this.loading = true;
      api
        .getNews()
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let news = response.Data.news;
            this.allNews = news;
            let cutNewsArr = news.slice(0, 3);
            this.news = cutNewsArr;
          } else {
            this.$message({
              showClose: true,
              message: `${responseMessage}`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
  mounted() {
    // this.fetchNews();

    const openBtn = this.$refs.openBtn;
    const closeBtn = this.$refs.closeBtn;
    const mobileLinks = Array.from(this.$refs.mobileLinks.childNodes);

    closeBtn.addEventListener(
      "click",
      () => (this.$refs.mobileMenu.style.display = "none")
    );

    openBtn.addEventListener(
      "click",
      () => (this.$refs.mobileMenu.style.display = "initial")
    );

    mobileLinks.forEach((menu) => {
      menu.addEventListener(
        "click",
        () => (this.$refs.mobileMenu.style.display = "none")
      );
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_home";
</style>
