import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import store from "../store";
import VueAppInsights from "vue-application-insights";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "default-layout"
    }
  },
  {
    path: "/developers",
    name: "Developers",
    component: () => import("../views/Developers.vue"),
    meta: {
      layout: "default-layout"
    }
  },
  {
    path: "/login",
    name: "Login Page",
    component: Login,
    meta: {
      layout: "home-layout"
    }
  },
  {
    path: "/signup",
    name: "Sign up",
    component: () => import("../components/Signup/Signup.vue"),
    children: [
      {
        path: "question",
        name: "Question",
        component: () => import("../components/Signup/Question.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "preference",
        name: "Preference",
        component: () => import("../components/Signup/SignupPreference.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "with-one-pay",
        name: "OnePay-Signup",
        component: () => import("../components/Signup/OnePay.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "new-user-form",
        name: "New User",
        component: () => import("../components/Signup/NewUserForm.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "existing-user",
        name: "Existing User",
        component: () => import("../components/Signup/ExistingUser.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "card-details",
        name: "Card Details",
        component: () => import("../components/Signup/CardDetails.vue"),
        meta: {
          layout: "home-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "existing-user-form",
        name: "Existing User Form",
        component: () => import("../components/Signup/ExistingUserForm.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "security-questions",
        name: "Security Questions",
        component: () => import("../components/Signup/SecurityQuestions.vue"),
        meta: {
          layout: "home-layout"
        }
      }
    ]
  },
  {
    path: "/dashboard-home",
    name: "Dashboard Home",
    component: () => import("../components/Sidebar/DashboardHome.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },

  {
    path: "/password-reset",
    name: "Forget Password",
    component: () => import("../components/ForgetPassword/ForgetPassword.vue"),
    children: [
      {
        path: "account",
        name: "Forget Number",
        component: () => import("../components/ForgetPassword/AccountNo.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "otp",
        name: "Forget OTP",
        component: () => import("../components/ForgetPassword/OTP.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "questions",
        name: "Password Security Questions",
        component: () => import("../components/ForgetPassword/Questions.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "pin",
        name: "Password PIN",
        component: () => import("../components/ForgetPassword/Pin.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "password",
        name: "Password Password",
        component: () => import("../components/ForgetPassword/Password.vue"),
        meta: {
          layout: "home-layout"
        }
      },
    ]
  },

  {
    path: "/device-change",
    name: "Device Change",
    component: () => import("../components/DeviceChange/DeviceChange.vue"),
    children: [
      {
        path: "index",
        name: "Device Change index",
        component: () => import("../components/DeviceChange/Index.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "account",
        name: "Device Change Account Number",
        component: () => import("../components/DeviceChange/AccountNo.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "otp",
        name: "Device Change OTP",
        component: () => import("../components/DeviceChange/OTP.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "questions",
        name: "Device Change Security Questions",
        component: () => import("../components/DeviceChange/Questions.vue"),
        meta: {
          layout: "home-layout"
        }
      },
      {
        path: "pin",
        name: "Device Change PIN",
        component: () => import("../components/DeviceChange/Pin.vue"),
        meta: {
          layout: "home-layout"
        }
      }
    ]
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../components/Dashboard/Dashboard.vue"),
    children: [
      {
        path: "history",
        name: "Transaction History",
        component: () =>
          import("../components/Dashboard/TransactionHistory.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "statements",
        name: "Account Statements",
        component: () =>
          import("../components/Dashboard/Statements.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "transfer",
        name: "Transfer",
        component: () => import("../components/Dashboard/Transfer.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "airtime-and-data",
        name: "Airtime & Data",
        component: () => import("../components/Dashboard/Airtime&Data.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "bills",
        name: "Bills",
        component: () => import("../components/Dashboard/Bills.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        children: [
          {
            path: "bills-payment",
            name: "Bills Payment",
            component: () => import("../components/Bills/BillsPayment.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          },
          {
            path: "bills-categories",
            name: "Bills Categories",
            component: () => import("../components/Bills/AllBills.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          },
          {
            path: "category",
            name: "Category",
            component: () => import("../components/Bills/SingleBill.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          },
          {
            path: "sub-categories",
            name: "Sub Categories",
            component: () => import("../components/Bills/SubSingleBill.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          },
          {
            path: "/dashboard/accounts",
            name: "Account Management",
            component: () => import("../components/Dashboard/Accounts.vue"),
            meta: {
              layout: "dashboard-layout"
            }
          },
          {
            path: "/account-opening",
            name: "Account Type",
            component: () => import("../components/Accounts/AccountType.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          },
          {
            path: "/account-creation",
            name: "Account Creation",
            component: () => import("../components/Accounts/AccountCreation.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          },
          {
            path: "/account-product",
            name: "Open Account",
            component: () => import("../components/Accounts/CurrentProduct.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          },
          {
            path: "pay-biller",
            name: "Pay Biller",
            component: () => import("../components/Bills/PayBiller.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          },
        ]
      },
      {
        path: "cards",
        name: "Card Management",
        component: () => import("../components/Dashboard/Cards.vue"),
        children: [
          {
            path: "cards-management",
            name: "Cards Management",
            component: () => import("../components/Cards/CardsManagement.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          },
          {
            path: "cards-list",
            name: "Cards List",
            component: () => import("../components/Cards/CardsList.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          },
          {
            path: "card-actions",
            name: "Card Actions",
            component: () => import("../components/Cards/CardActions.vue"),
            meta: {
              layout: "dashboard-layout"
            },
            beforeEnter: (to, from, next) => {
              if (store.getters.sessionId === null) {
                next("/login");
              } else {
                next();
              }
            }
          }
        ]
      },
      {
        path: "local-transfer",
        name: "Local Transfer",
        component: () => import("../components/Transfers/LocalTransfer.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "foreign-transfer",
        name: "Foreign Transfer",
        component: () => import("../components/Transfers/ForeignTransfer.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "pay-ecommerce",
        name: "Pay E-commerce",
        component: () => import("../components/Transfers/PayEcommerce.vue"),
        meta: {
          layout: "dashboard-layout"
        }
      },
      {
        path: "cheque-deposit",
        name: "Cheque Deposit",
        component: () => import("../components/Transfers/ChequeDeposit.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      }
    ]
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    component: () => import("../components/Sidebar/EditProfile.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  // {
  //   path: "/card-management",
  //   name: "Card Management",
  //   component: () => import("../components/Sidebar/CardManagement.vue"),
  //   meta: {
  //     layout: "dashboard-layout"
  //   }
  // },
  {
    path: "/dispute-management",
    name: "Dispute Management",
    component: () => import("../components/Sidebar/DisputeManagement.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("../components/Sidebar/Support.vue"),
    children: [
      {
        path: "actions",
        name: "Support Features",
        component: () => import("../components/Support/SupportActions.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "change-password",
        name: "Change Password",
        component: () => import("../components/Support/LoginPassword.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "change-pin",
        name: "Change Pin",
        component: () => import("../components/Support/TransactionPin.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "update-questions",
        name: "Update Security Questions",
        component: () =>
          import("../components/Support/UpdateSecurityQuestions.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      }
    ]
  },
  {
    path: "/referrals",
    name: "Referrals",
    component: () => import("../components/Sidebar/Referrals"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: "/scheduled-transactions",
    name: "Scheduled Transactions",
    component: () =>
      import("../components/Transfers/ScheduledTransactions.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: "/saved-beneficiaries",
    name: "Saved Beneficiaries",
    component: () => import("../components/Sidebar/SavedBeneficiaries.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: "/ajo-scheme",
    name: "Ajo Scheme",
    component: () => import("../components/Sidebar/AjoScheme.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: "/pickup",
    name: "Pick-Up",
    component: () => import("../components/Sidebar/Pickup.vue"),
    children: [
      {
        path: "actions",
        name: "Pickup Features",
        component: () => import("../components/Pickup/PickupActions.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "atm",
        name: "ATM",
        component: () => import("../components/Pickup/PickupAtm.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: "in-branch",
        name: "In-Branch",
        component: () => import("../components/Pickup/PickupBranch.vue"),
        meta: {
          layout: "dashboard-layout"
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.sessionId === null) {
            next("/login");
          } else {
            next();
          }
        }
      }
    ]
  },
  {
    path: "/contact-account-officer",
    name: "Contact Account Officer",
    component: () => import("../components/Sidebar/AccountOfficer.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: "/e-naira",
    name: "E-naira",
    component: () => import("../components/E-naira/E-naira.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: "/e-naira-login",
    name: "E-Naira-Login",
    component: () => import("../components/E-naira/E-naira-login.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: "/e-naira-transactions",
    name: "E-naira Transactions",
    component: () => import("../components/E-naira/E-naira-transactions.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: "/e-naira-withdrawal",
    name: "E-naira Withdrawal",
    component: () => import("../components/E-naira/E-naira-withdrawal.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: "/e-naira-deposit",
    name: "E-naira Deposit",
    component: () => import("../components/E-naira/E-naira-deposit.vue"),
    meta: {
      layout: "dashboard-layout"
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.sessionId === null) {
        next("/login");
      } else {
        next();
      }
    }
  },
  
  
];

const router = new VueRouter({
  // mode: "hash",
  mode: "history",
  linkActiveClass: "active-bar",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

Vue.use(VueAppInsights, {
  baseName: "OneBank Web",
  id: "d4640f84-df56-4046-9d32-48670b2d7eb6",
  router
});

export default router;
