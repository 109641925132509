<template>
  <div class="container">
    <div class="main">
      <nav class="navTwo">
        <img
          @click="$router.push('/dashboard-home')"
          class="main__logo"
          src="../../assets/images/nav-logo.svg"
          alt="nav-logo"
        />

        <div class="navTwo__right">
          <img
            @click="logout"
            src="../../assets/images/logout-icon.svg"
            alt="logout-icon"
          />
          <div class="navTwo__burger">
            <img
              id="mobileMenuBtn"
              src="../../assets/images/toggle.svg"
              alt="toggle-icon"
            />
          </div>
        </div>
      </nav>

      <img
        @click="$router.push('/dashboard-home')"
        class="main__left"
        src="../../assets/images/nav-logo.svg"
        alt="nav-logo"
      />

      <div class="main__right">
        <img
          @click="logout"
          src="../../assets/images/logout-icon.svg"
          alt="logout-icon"
        />
        <app-nav-user class="user"></app-nav-user>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import NavUser from "@/components/layouts/NavUser.vue";
export default {
  computed: {
    ...mapState(["user"]),
  },
  components: {
    "app-nav-user": NavUser,
  },
  methods: {
    logout() {
      let payload = { SMID: this.user.profileInfo.SMID };

      this.$store.commit("toggleLayoutLoading", true);

      api
        .handleCustomerLogout(payload)
        .then((response) => {
          this.$store.commit("toggleLayoutLoading", false);
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `Signed out successfully.`,
              type: "success",
              duration: 10000,
            });

            this.$store.commit("setUser", null);
            this.$store.commit("setAccountsPayload", null);
            this.$store.commit("setSessionId", null);
            localStorage.clear();
            sessionStorage.clear();

            this.$router.push("/");
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLayoutLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.4rem 2rem;
  border: none;
  border-bottom: 1px solid #fafafa;
  background: #ffffff;

  min-height: 6.4rem;

  &__left,
  &__right img,
  &__logo {
    cursor: pointer;
  }

  &__right {
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }

  // 768px
  @media only screen and (max-width: 48em) {
    &__logo {
      display: block;
    }
    &__left {
      display: none;
    }
  }
}

.navTwo {
  display: none;
  &__burger {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  // 768px
  @media only screen and (max-width: 48em) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;

    padding: 1.8rem 2.5rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #ffffff;
    border: 1px solid #f2f2f2;
    width: 100%;
    transform: translateY(0px);
    user-select: none;
    animation-name: fadeIn;
    animation-duration: 0.5s;

    &__right {
      display: flex;
      align-items: center;
      column-gap: 1.5rem;
      cursor: pointer;
    }
  }
}

.user {
  display: none;
  // 768px
  @media only screen and (min-width: 48em) {
    display: flex;
  }
}
</style>
