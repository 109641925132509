<template>
  <el-carousel
    indicator-position="none"
    arrow="never"
    height="100%"
    :interval="interval"
    class="wrapper"
  >
    <h1 class="leading">Welcome</h1>
    <el-carousel-item class="carousel carousel__one">
      <h2>Ajo Scheme</h2>
      <p>
        Contribute and share among your family & friends
      </p>
    </el-carousel-item>
    <el-carousel-item class="carousel carousel__two">
      <h2>Upgrade to Savings Account</h2>
      <p>
        Switch up Wallet Account to Savings Account
      </p>
    </el-carousel-item>
    <el-carousel-item class="carousel carousel__three">
      <h2>Investment and Loans</h2>
      <p>
        Invest and make money with your Account
      </p>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {
      interval: 5000
    };
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
}
.leading {
  position: absolute;
  top: 10%;
  width: 100%;
  z-index: 3;
  color: #ffffff;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 4.8rem;
  text-align: center;
  letter-spacing: 0.05em;
}
.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__one {
    background: url("~@/assets/images/background-one.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__two {
    background: url("~@/assets/images/background-two.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__three {
    background: url("~@/assets/images/background-three.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  h2 {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3.2rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;

    margin-bottom: 0.8rem;
  }
  p {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
}
</style>
