import store from "@/store";

import axios from "axios";
import CryptoJS from "crypto-js";

import config from "../config";

import clientOS from "../ultilities/detectClientOS";
import clientDevice from "../ultilities/detectClientDevice";
import clientBrowser from "../ultilities/detectClientBrowser";
import { getDeviceId } from "../ultilities/BrowserFingerPrint/index";

// Axios global configs
let encryptedData, ivHeader;

axios.defaults.headers["ONB-CID"] = config.clientId;

axios.interceptors.request.use(function(config) {
  config.headers.post["ONB-CS"] = encryptedData;
  config.headers.post["ONB-IV"] = ivHeader;
  config.headers.post["sessionId"] = store.state.sessionId;
  return config;
});

axios.interceptors.request.use(function(config) {
  config.headers.get["sessionId"] = store.state.sessionId;
  return config;
});

// Response interception
// axios.interceptors.response.use(
//   function(response) {
//     let key = config.clientKey;
//     key = CryptoJS.enc.Base64.parse(key);

//     let iv = response.headers["onb-iv"];
//     iv = CryptoJS.enc.Base64.parse(iv);

//     let data = response.data;

//     let decrypted = CryptoJS.AES.decrypt(data, key, {
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.ZeroPadding,
//       iv: iv,
//     }).toString(CryptoJS.enc.Utf8);

//     decrypted = JSON.parse(decrypted);

//     APIfootprint(response);

//     return decrypted;
//   },
axios.interceptors.response.use(
  function(response) {
    let data = response.data;
    if (
      response.headers["onb-iv"] === null ||
      response.headers["onb-iv"] === undefined
    ) {
      return response;
    } else {
      let iv = response.headers["onb-iv"];
      iv = CryptoJS.enc.Base64.parse(iv);
      let key = config.clientKey;
      key = CryptoJS.enc.Base64.parse(key);

      let decrypted = CryptoJS.AES.decrypt(data, key, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
        iv: iv,
      }).toString(CryptoJS.enc.Utf8);

      decrypted = JSON.parse(decrypted);
      return decrypted;
    }
  },

  function(error) {
    if (error.response) {
      let key = config.clientKey;
      key = CryptoJS.enc.Base64.parse(key);

      let iv = error.response.headers["onb-iv"];
      iv = CryptoJS.enc.Base64.parse(iv);

      let data = error.response.data;

      let decrypted = CryptoJS.AES.decrypt(data, key, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
        iv: iv,
      }).toString(CryptoJS.enc.Utf8);

      decrypted = JSON.parse(decrypted);

      return decrypted;
    } else {
      return error.message;
    }

    // return Promise.reject(error);
  }
);

// Log in axios instance
const axiosTwo = axios.create();

axiosTwo.interceptors.request.use(function(config) {
  config.headers.post["ONB-CS"] = encryptedData;
  config.headers.post["ONB-IV"] = ivHeader;
  return config;
});

axiosTwo.interceptors.response.use(
  function(response) {
    let key = config.clientKey;
    key = CryptoJS.enc.Base64.parse(key);

    let iv = response.headers["onb-iv"];
    iv = CryptoJS.enc.Base64.parse(iv);

    let id = response.headers["sessionid"];

    store.commit("setSessionId", id);

    // sessionId = response.headers["sessionid"];

    let data = response.data;

    let decrypted = CryptoJS.AES.decrypt(data, key, {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
      iv: iv,
    }).toString(CryptoJS.enc.Utf8);

    decrypted = JSON.parse(decrypted);

    return decrypted;
  },

  function(error) {
    if (error.response) {
      let key = config.clientKey;
      key = CryptoJS.enc.Base64.parse(key);

      let iv = error.response.headers["onb-iv"];
      iv = CryptoJS.enc.Base64.parse(iv);

      let data = error.response.data;

      let decrypted = CryptoJS.AES.decrypt(data, key, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
        iv: iv,
      }).toString(CryptoJS.enc.Utf8);

      decrypted = JSON.parse(decrypted);

      return decrypted;
    } else {
      return error.message;
    }
  }
);

// axios instance for request statement
const axiosThree = axios.create();

// Functions

// https://api.ipify.org - Used to get user IP Address
async function getUserIp() {
  let userIp;
  try {
    await fetch("https://api.ipify.org")
      .then((response) => response.text())
      .then((data) => (userIp = data));
  } catch (error) {
    userIp = `Unable to fetch userIp`;
  }
  return userIp;
}

async function getUserDeviceId() {
  let result;
  try {
    await getDeviceId().then((fingerprint) => (result = fingerprint));
  } catch (error) {
    result = `Web`;
  }
  return result;
}

function performHmacEncryption(value) {
  const clientSecret = CryptoJS.enc.Base64.parse(config.clientSecret).toString(
    CryptoJS.enc.Utf8
  );

  encryptedData = CryptoJS.enc.Base64.stringify(
    CryptoJS.HmacSHA256(JSON.stringify(value), clientSecret)
  );

  return encryptedData;
}

function performAesEncryption(value) {
  const key = CryptoJS.enc.Base64.parse(config.clientKey);
  ivHeader = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Base64);
  const iv = CryptoJS.enc.Base64.parse(ivHeader);

  const data = CryptoJS.AES.encrypt(JSON.stringify(value), key, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
    iv: iv,
  }).toString();

  return `"${data}"`;
}

export default {
  pingServerOne: () => {
    let method = "post";
    let url = config.serverUriOne + "/ping";
    return axios({ method, url });
  },
  pingServerTwo: () => {
    let method = "post";
    let url = config.serverUriTwo + "/ping";
    return axios({ method, url });
  },
  handleCustomerLogin: async (dataOne) => {
    const ip = await getUserIp();
    const deviceId = await getUserDeviceId();

    let dataTwo = {
      logDetails: {
        DeviceOS: clientOS,
        DeviceIMEI: `${deviceId}`,
        HardwareIMEI: `${deviceId}`,
        IPAddress: `${ip}`,
      },
    };
    let dataThree = { ...dataOne, ...dataTwo };

    encryptedData = performHmacEncryption(dataThree);
    let data = performAesEncryption(dataThree);

    let method = "post";
    let url = config.serverUriOne + "/customerlogin";

    return axiosTwo({ method, url, data });
  },
  createNewSession: async (dataOne) => {
    const ip = await getUserIp();
    const deviceId = await getUserDeviceId();

    let dataTwo = {
      logDetails: {
        DeviceOS: clientOS,
        DeviceIMEI: `${deviceId}`,
        HardwareIMEI: `${deviceId}`,
        IPAddress: `${ip}`,
      },
    };
    let dataThree = { ...dataOne, ...dataTwo };

    encryptedData = performHmacEncryption(dataThree);
    let data = performAesEncryption(dataThree);

    let method = "post";
    let url = config.serverUriOne + "/generateSession";

    return axiosTwo({ method, url, data });
  },
  handleCustomerLogout: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/customerlogout";

    return axios({ method, url, data });
  },
  getAccountsByBvn: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getaccountsbybvn";

    return axios({ method, url, data });
  },
  getWalletDetails: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getwallet";

    return axios({ method, url, data });
  },
  getAccountDetailsByNuban: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getaccount";

    return axios({ method, url, data });
  },
  getGomoneyAccountDetailsByNuban: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/gomoneynameEnquiry";

    return axios({ method, url, data });
  },
  getCustomerDetails: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getcustomerdetails";

    return axios({ method, url, data });
  },
  handleInterbankNameEnquiry: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/interbanknameenquiry";

    return axios({ method, url, data });
  },
  handleBvnEnquiry: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);
    let method = "post";
    let url = config.serverUriOne + "/bvnenquiry";

    return axios({ method, url, data });
  },
  getGomoneyDetails: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/gomoneynameEnquiry";

    return axios({ method, url, data });
  },
  handleOnboardWalletOnlyCustomers: async (dataOne) => {
    const ip = await getUserIp();
    const deviceId = await getUserDeviceId();

    let dataTwo = {
      deviceimei: `${deviceId}`,
      deviceos: clientOS,
      devicemake: clientDevice,
      devicemodel: clientBrowser,
      HardwareIMEI: `${deviceId}`,
      ipaddress: `${ip}`,
    };
    let dataThree = { ...dataOne, ...dataTwo };

    encryptedData = performHmacEncryption(dataThree);
    let data = performAesEncryption(dataThree);

    let method = "post";
    let url = config.serverUriOne + "/onboardwalletonlycustomer";

    return axios({ method, url, data });
  },
  getActivationCode: async (dataOne) => {
    const ip = await getUserIp();
    const deviceId = await getUserDeviceId();

    let dataTwo = {
      logDetails: {
        deviceOS: clientOS,
        deviceIMEI: `${deviceId}`,
        HardwareIMEI: `${deviceId}`,
        iPAddress: `${ip}`,
      },
    };
    let dataThree = { ...dataOne, ...dataTwo };

    encryptedData = performHmacEncryption(dataThree);
    let data = performAesEncryption(dataThree);

    let method = "post";
    let url = config.serverUriOne + "/generate_activation_code";

    return axios({ method, url, data });
  },
  validateActivationCode: async (dataOne) => {
    const ip = await getUserIp();
    const deviceId = await getUserDeviceId();

    let dataTwo = {
      logDetails: {
        deviceOS: clientOS,
        deviceIMEI: `${deviceId}`,
        HardwareIMEI: `${deviceId}`,
        iPAddress: `${ip}`,
      },
    };
    let dataThree = { ...dataOne, ...dataTwo };

    encryptedData = performHmacEncryption(dataThree);
    let data = performAesEncryption(dataThree);

    let method = "post";
    let url = config.serverUriOne + "/verify_activation_code";

    return axios({ method, url, data });
  },
  getSecurityQuestions: () => {
    let method = "get";
    let url = config.serverUriOne + "/getquestions";
    return axios({ method, url });
  },
  setSecurityQuestions: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/adduserquestion";

    return axios({ method, url, data });
  },
  handleOnboardSterlingCustomers: async (dataOne) => {
    const ip = await getUserIp();
    const deviceId = await getUserDeviceId();

    let dataTwo = {
      deviceimei: `${deviceId}`,
      deviceos: clientOS,
      devicemake: clientDevice,
      devicemodel: clientBrowser,
      HardwareIMEI: `${deviceId}`,
      ipaddress: `${ip}`,
    };
    let dataThree = { ...dataOne, ...dataTwo };

    encryptedData = performHmacEncryption(dataThree);
    let data = performAesEncryption(dataThree);

    let method = "post";
    let url = config.serverUriOne + "/onboardsterlingcustomerwithotp";

    return axios({ method, url, data });
  },
  addBeneficiary: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/addbeneficiary";

    return axios({ method, url, data });
  },
  getBeneficiary: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getbeneficiary";

    return axios({ method, url, data });
  },
  getSterlingBeneficiary: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getsterlingbeneficiary";

    return axios({ method, url, data });
  },
  getNonSterlingBeneficiary: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getnonsterlingbeneficiary";

    return axios({ method, url, data });
  },
  handleSterlingToSterlingTransfer: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/intrabankfundstransferSM";

    return axios({ method, url, data });
  },
  handleSterlingToWalletTransfer: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/sterlingtowallettransfer";

    return axios({ method, url, data });
  },
  handleSterlingToGomoneyTransfer: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/intrabankfundstransferGoMoney";

    return axios({ method, url, data });
  },
  handleWalletToSterlingTransfer: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/wallettosterling";

    return axios({ method, url, data });
  },
  handleWalletToWalletTransfer: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/wallettransfer";

    return axios({ method, url, data });
  },
  handleWalletToGomoneyTransfer: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/wallettogomoney";

    return axios({ method, url, data });
  },
  getBanks: () => {
    let method = "get";
    let url = config.serverUriOne + "/getbanks";
    return axios({ method, url });
  },
  handleSterlingToInterbankTransfer: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/interbankfundstransfer";

    return axios({ method, url, data });
  },
  handleWalletToInterbankTransfer: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/wallettootherbank";

    return axios({ method, url, data });
  },

  // Scheduled - later payments
  scheduleIntrabankPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/addintrabankschedulepayment";

    return axios({ method, url, data });
  },
  scheduleInterbankPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/addinterbankschedulepayment";

    return axios({ method, url, data });
  },
  scheduleInterbankFcyPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/addinterbankfcyschedulepayment";

    return axios({ method, url, data });
  },
  scheduleBillsPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/schedulebillpayment";

    return axios({ method, url, data });
  },
  getPendingScheduledPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/getpendingscheduledpayment";

    return axios({ method, url, data });
  },
  cancelScheduledPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/cancelscheduledpayment";

    return axios({ method, url, data });
  },

  // Recurring - repeating payments
  handleIntrabankRecurringPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/addintrabankrecurringpayment";

    return axios({ method, url, data });
  },
  handleInterbankRecurringPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/addinterbankrecurringpayment";

    return axios({ method, url, data });
  },
  handleInterbankFcyRecurringPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/addinterbankfcyrecurringpayment";

    return axios({ method, url, data });
  },
  getPendingRecurringPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/getongoingrecurringpayment";

    return axios({ method, url, data });
  },
  cancelRecurringPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/cancelrecurringpayment";

    return axios({ method, url, data });
  },

  // Bulk payments
  handleIntrabankBulkPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/addintrabankbulkpayment";

    return axios({ method, url, data });
  },
  handleInterbankBulkPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/addinterbankbulkpayment";

    return axios({ method, url, data });
  },
  handleInterbankFcyBulkPayment: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/addinterbankfcybulkpayment";

    return axios({ method, url, data });
  },
  getInterBankTransferFee: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/getinterbanktransferfee";

    return axios({ method, url, data });
  },
  getInterBankTransferFeeWallet: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/getinterbanktransferfeewallet";

    return axios({ method, url, data });
  },

  // Airtime and Data
  handleAirtimeRecharge: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/vendairtime";

    return axios({ method, url, data });
  },
  getDataPlans: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getdataplan";

    return axios({ method, url, data });
  },
  handleDataRecharge: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/venddata";

    return axios({ method, url, data });
  },

  // Cards
  handlePinValidation: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/validatetransactionpin";

    return axios({ method, url, data });
  },
  setCardPin: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/setcardpin";

    return axios({ method, url, data });
  },
  blockCard: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/blockcardwithcustomerid";

    return axios({ method, url, data });
  },
  getCardLimits: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getcardlimit";

    return axios({ method, url, data });
  },
  handleCardPurchaseLimit: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/modifycardpurchaselimit";

    return axios({ method, url, data });
  },
  handleCardPaymentLimit: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/modifycardpaymentlimit";

    return axios({ method, url, data });
  },
  handleCardWebLimit: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/modifycardweblimit";

    return axios({ method, url, data });
  },

  // Transaction history
  getTransactionHistory: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriTwo + "/getfundstransferbysoucenuban";

    return axios({ method, url, data });
  },

  // Bills payment
  getBillerCategories: () => {
    let method = "get";
    let url = config.serverUriOne + "/getbillercategory";
    return axios({ method, url });
  },
  getAirtimeBillers: () => {
    let method = "get";
    let url = config.serverUriOne + "/getairtimebiller";
    return axios({ method, url });
  },
  getDataBillers: () => {
    let method = "get";
    let url = config.serverUriOne + "/getdatabiller";
    return axios({ method, url });
  },
  getBillerByCategoryId: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getbillerbycategoryid";

    return axios({ method, url, data });
  },
  getBillerPayItems: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getpayitembybillerid";

    return axios({ method, url, data });
  },
  validateCustomerInfoForBills: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/quickpayvalidatecustomerinformation";

    return axios({ method, url, data });
  },
  payBiller: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/paybiller";

    return axios({ method, url, data });
  },

  // Cheque deposit
  handleChequeDeposit: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/chequedeposit";

    return axios({ method, url, data });
  },

  // Get news for landing page
  getNews: () => {
    let method = "get";
    let url = config.serverUriOne + "/getnewsrate";
    return axios({ method, url });
  },
  // Get branch
  getBranch: () => {
    let method = "get";
    let url = config.serverUriOne + "/getbranch";
    return axios({ method, url });
  },

  // Change login password
  changeLoginPassword: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/changeloginpassword";

    return axios({ method, url, data });
  },

  // Change Transaction Pin
  changeTransactionPin: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/changetransactionpin";

    return axios({ method, url, data });
  },

  // ONE-PAY-MIGRATION
  onePayOtpGeneration: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);
    let method = "post";
    let url = config.serverUriOne + "/onboardonepayusergenerateotp";

    return axios({ method, url, data });
  },
  onboardOnePayUser: async (payload) => {
    const ip = await getUserIp();
    let dataTwo = {
      ipaddress: `${ip}`,
    };
    let dataThree = { ...payload, ...dataTwo };
    encryptedData = performHmacEncryption(dataThree);
    let data = performAesEncryption(dataThree);

    let method = "post";
    let url = config.serverUriOne + "/onboardonepayuser";
    return axios({ method, url, data });
  },
  onePayUserWithNewUsername: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);
    let method = "post";
    let url = config.serverUriOne + "/onboardonepayuserwithnewusername";
    return axios({ method, url, data });
  },

  // Log customer footprint
  customerFootprint: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let url = config.serverUriOne + "/addcustomererror";
    let method = "post";

    return axios({ method, url, data });
  },
  // Cash pickup
  setPickupAtm: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/cardlessonatm";

    return axios({ method, url, data });
  },

  setPickupBranch: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/cashoutatbranch";

    return axios({ method, url, data });
  },

  //Get available products
  getAvailableProducts: () => {
    let method = "get";
    let url = config.serverUriThree + "/GetActiveProducts";
    return axios({ method, url });
  },
  //Account number generator
  accountNumberGenerator: (data) => {
    let method = "post";
    let url = config.serverUriThree + "/OpenAccountsDocVersion";
    return axios({ method, url, data });
  },
  //Account number generator
  uploadImageToAzure: (data) => {
    let method = "post";
    let url = config.serverUriThree + "/UploadImageToAzureWithResponseCode";
    return axios({ method, url, data });
  },
  // Generate Otp
  generateOtp: (data) => {
    let method = "post";
    let url = config.serverUriThree + "/Generateotp";
    return axios({ method, url, data });
  },
  // Validate Otp
  validateOtp: (data) => {
    let method = "post";
    let url = config.serverUriThree + "/ValidateOTP";
    return axios({ method, url, data });
  },
  // E-Naira
  loginEnaira: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriFour + "/commerceLogin";

    return axios({ method, url, data });
  },
  getCommerceBalance: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriFour + "/commerceGetBalance";

    return axios({ method, url, data });
  },
  getCommerceHistory: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriFour + "/commerceGetHistory";

    return axios({ method, url, data });
  },
  commerceCreateWithdrawalRequest: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriFour + "/commerceCreateWithdrawalRequest";

    return axios({ method, url, data });
  },
  commerceCreateDepositRequest: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriFour + "/commerceCreateDepositRequest";

    return axios({ method, url, data });
  },
  enableCommerceWithdrawal: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriFour + "/commerceEnableWithdrawal";

    return axios({ method, url, data });
  },
  enableMerchantWithdrawal: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriFour + "/merchantEnableWithdrawal";

    return axios({ method, url, data });
  },
  eNairaMerchantLogin: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriFour + "/merchantLogin";

    return axios({ method, url, data });
  },
  eNairaMerchantBalance: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriFour + "/merchantGetBalance";

    return axios({ method, url, data });
  },
  getMerchantHistory: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriFour + "/merchantGetHistory";

    return axios({ method, url, data });
  },

  // Forget Password
  generateOTPWithAny: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/GenerateOTPRequestWithAny";

    return axios({ method, url, data });
  },

  verifyOTP: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/verifyotp";

    return axios({ method, url, data });
  },

  getQuestionByUserId: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/getquestionbyuserid";

    return axios({ method, url, data });
  },

  VerifyQuestionByUserID: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/VerifyQuestionsByUserID";

    return axios({ method, url, data });
  },

  forgotPassword: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/forgotpasswordnew";

    return axios({ method, url, data });
  },

  generateTransactionOtp: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/generateotp";

    return axios({ method, url, data });
  },
  generateWalletOtp: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/generatewalletotp";

    return axios({ method, url, data });
  },
  verifyTransactionOtp: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/verifyotp";

    return axios({ method, url, data });
  },

  verifyWalletOtp: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/verifyotp";

    return axios({ method, url, data });
  },
  //Request Statement
  requestStatement: (payload) => {
    let url = config.serverUriFive + "/Mystatement/statement-request";
    return axiosThree.post(url, payload, {
      auth: {
        username: config.serverUriFiveAuthUsername,
        password: config.serverUriFiveAuthPassword,
      },
    });
  },

  //update wallet to regular profile
  updateWalletToRegularProfile: (payload) => {
    encryptedData = performHmacEncryption(payload);
    let data = performAesEncryption(payload);

    let method = "post";
    let url = config.serverUriOne + "/updatewallettoregularprofile";

    return axios({ method, url, data });
  },

  //addUserDevice
  addUserDevice: async (dataOne) => {
    const ip = await getUserIp();
    const deviceId = await getUserDeviceId();

    let dataTwo = {
      deviceos: clientOS,
      deviceimei: `${deviceId}`,
      devicemake: clientDevice,
      devicemodel: clientBrowser,
      ipaddress: `${ip}`,
    };
    let dataThree = { ...dataOne, ...dataTwo };

    encryptedData = performHmacEncryption(dataThree);
    let data = performAesEncryption(dataThree);

    let method = "post";
    let url = config.serverUriOne + "/adduserdevicenew";

    return axios({ method, url, data });
  },
};
