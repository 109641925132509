<template>
  <div
    class="grid-container"
    v-loading="layoutLoading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <app-nav class="header"></app-nav>

    <app-sidenav class="sidenav"></app-sidenav>

    <main class="main">
      <el-page-header
        class="panel"
        v-if="$route.name !== 'Dashboard Home'"
        @back="$router.go(-1)"
        :content="$route.name"
      >
      </el-page-header>
      <transition name="slide-fade">
        <div>
          <slot />
        </div>
      </transition>
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";

// Main layout components
import MainNavbar from "@/components/layouts/MainNavbar.vue";
import MainSidebar from "@/components/layouts/MainSidebar.vue";

export default {
  name: "analytics",
  computed: {
    ...mapState(["user", "layoutLoading"])
  },
  components: {
    "app-nav": MainNavbar,
    "app-sidenav": MainSidebar
  },
  mounted() {
    const menuIconEl = document.querySelector(".navTwo__burger");
    const sidenavEl = document.querySelector(".sidenav");

    const navlinks = document.querySelectorAll(".nav__link");

    const toggleClassName = (el, className) => {
      if (el.classList.contains(className)) {
        el.classList.remove(className);
      } else {
        el.classList.add(className);
      }
    };

    menuIconEl.addEventListener("click", () => {
      toggleClassName(sidenavEl, "active");
    });

    navlinks.forEach(nav => {
      nav.addEventListener("click", () => {
        sidenavEl.classList.remove("active");
      });
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_dashboardLayout";
</style>
